body {
    margin: 0 !important;
    padding: 0 !important;
}

#image {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}

/* 
#vocads-iframe-container {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0px !important;
} */
