@media (max-width: 480px) {
    div.vocads-widget-content-inner {
        height: 240px !important;
    }
    div.vocads-widget-content-header {
        padding: 10px 0 !important;
    }
    div.vocads-widget-content-container-main {
        width: 250px !important;
    }
    div.vocads-widget-content-header h3.vocads-widget-title {
        font-size: 15px !important;
    }
    h3.vocads-widget-current-question {
        font-size: 14px !important;
    }
    div.vocads-widget-content-header span,
    small.vocads-widget-mic-status,
    ul.vocads-options-ul {
        font-size: 11px !important;
    }
    .vocads-widget-container-main > .vocads-widget-button-container,
    .vocads-widget-button-container,
    .vocads-widget-button-container.vocads-widget-button-minimize {
        right: 20px !important;
        width: 50px !important;
        height: 50px !important;
        bottom: 15px !important;
    }
    div.vocads-widget-container-main {
        bottom: 15px !important;
        right: 20px !important;
    }
    div.vocads-widget-animated-circle,
    div.vocads-widget-animated-circle-inner,
    div.vocads-widget-button-container svg {
        width: 20px !important;
        height: 20px !important;
    }
    div.vocads-widget-ribbon span {
        font-size: 8px !important;
        left: -26px !important;
        line-height: 13px !important;
    }
    div.vocads-widget-content-header svg {
        width: 7px !important;
        margin-bottom: -2px !important;
        margin-right: 3px !important;
    }
}
